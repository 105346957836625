// Here you can add other styles
.footer {
  justify-content: center;
  font-size: 0.9rem;
}

.pagination {
  justify-content: right;
}

.pagination button {
  margin-right: 5px;
}

.pagination select {
  width: 100px;
}

.table input {
  width: 5rem;
  border-radius: 0.3rem;
  padding: 5px;
  border: 1px solid #ced4da;
  height: 25px;
  font-size: 0.7rem;
}

.req-table th:nth-child(5), .req-table th:nth-child(6) {
  text-align: center;
}

.req-table th:nth-last-child(2) {
  text-align: center;
}

.table input[type='checkbox'] {
  width: 100%;
}

.table select {
  width: 5rem;
  border-radius: 0.3rem;
  padding: 2px;
  border: 1px solid #ced4da;
  height: 25px;
  font-size: 0.7rem;
}

.table thead th span {
  font-weight: 600;
}

.table td {
  font-size: 0.8rem;
}

.td-middle td {
  vertical-align: middle !important;
}

.form-check-label {
  font-size: 0.8rem;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
}

.custom_label {
  font-size: 0.875rem;
}

th {
  font-size: 0.8rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.dashboard-card-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 4rem;
  height: 10rem;
}

.dashboard-card {
  margin-bottom: 0rem;
}

.gray-bg
{
  background-color: #f8f8f8;
  border-radius: 0.2rem;
}

.second-col{
  padding-left: 2rem;
}

.inline-calander
{
  width: 6rem !important;
}

@media only screen and (max-width: 768px) {
  .dashboard-card {
    margin-bottom: 1rem;
  }

  .second-col{
    padding-left: calc(var(--cui-gutter-x) * 0.5);
  }
}
